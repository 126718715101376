<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Mis actas administrativas</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="initialize( )"
              small
              rounded
            >
              Actualizar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="actas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  hide-default-footer
                  dense
                >

                  <template v-slot:item.estatus="{ item }">
                    <div v-if="!item.estatus">
                      <v-btn 
                        color="success"
                        small
                        tile
                        @click="abrirFirma( item )"
                      >
                        Firmar
                      </v-btn>
                    </div>
                    <v-chip small color="green" dark v-if="item.estatus == 1">Aceptada</v-chip>
                    <v-chip small color="black" dark v-if="item.estatus == 2">Rechazada</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      small
                    >
                      Actualizar
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.idactas_admin }}</strong>
          </span>
        </v-card-title>

        <v-card-text class="black--text">
          <div>
            Sanción
            <br/>
            {{ editedItem.asignado }}
          </div>

          <br/>

          <div>
            Sanción
            <br/>
            {{ editedItem.falta }}
          </div>

          <br/>

          <div>
            Sanción
            <br/>
            {{ editedItem.sancion }}
          </div>
          <br/>
          

          <v-textarea
            v-model="editedItem.motivo"
            readonly
            filled
            rows="3"
            label="Agrega una descripción"
          >
          </v-textarea>

          <div v-if="!editedItem.estatus">
            <v-radio-group
              v-model="editedItem.estatus"
              row
              hide-details
              class="ma-0"
            >
              <v-radio
                label="Aceptar"
                :value="1"
              ></v-radio>
              <v-radio
                label="Rechazar"
                :value="2"
              ></v-radio>
            </v-radio-group>
          </div>
          <v-chip small color="green" dark v-if="editedItem.estatus == 1">Aceptada</v-chip>
          <v-chip small color="black" dark v-if="editedItem.estatus == 2">Rechazada</v-chip>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            rounded
            dark
            small
            @click="close"
          >
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            dark
            small
            @click="save( editedItem )"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogFirma"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card outlined class="mb-2">
        <div>
          <canvas id="signature-pad" class="signature-pad" width="780" height="250"></canvas>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="savePNG()"        
            small
            class="mr-2"
          >
            Firmar documento
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="limpiar()"
            small
          >
            <span v-if="this.$vuetify.breakpoint.name == 'xs'">
              <v-icon color="primary">mdi-eraser</v-icon>
            </span>
            <span v-else>
              Limpiar
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'
  import SignaturePad from 'signature_pad'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      signaturePad:null,
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      defaultItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      // Filtros: 
      incio:null,
      fin:null,

      // tabla
      search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      actas: [],
      headers: [
        { text: 'ID'               , value: 'idactas_admin'  },
        { text: 'Empleado'         , value: 'asignado'       },
        { text: 'Falta'            , value: 'falta'          },
        { text: 'Sanción'          , value: 'sancion'        },
        { text: 'Motivo'           , value: 'motivo'         },
        { text: 'Estatus'          , value: 'estatus'        },
        { text: 'fecha_ creación'  , value: 'fecha_creacion' },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      faltas:[],
      sanciones:[],
      usuarios:[],
      dialogFirma: false,
      acta: null
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar actas admin.' : 'Editar actas admin.'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()      
    },

    methods: {

      abrirFirma( item ){
        this.dialogFirma = true
        this.acta = item
        setTimeout(() => {
          let canvas = document.getElementById('signature-pad');
          this.signaturePad = new SignaturePad(canvas);
        }, 2000);

      },

      initialize () {
        this.cargar = true
        this.actas = []
        return this.$http.get('actas.usuario/' + this.getdatosUsuario.iderp ).then(response=>{
          this.actas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.actas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.actas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['deleted'] = 1
        this.$http.put('actas.update/' + this.editedItem.idactas_admin, this.editedItem).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save ( item ) {
        // Cargamos al usuario que hizo el cambio
        this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('actas.update/' + item.idactas_admin, item).then(response=>{
          this.validarSuccess( response.data.message )
          this.initialize()
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async savePNG (){
        if(this.signaturePad.isEmpty()){
          this.validarErrorDirecto('Necesitas ingresar una firma')
        }else{
          this.cargar = true

          let url = this.signaturePad.toDataURL();

          // Archivo blob binario
          let blobBin = atob(url.split(',')[1]);
          let array = [];
          // Recorremos el resultado
          for(let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }

          // Convertimos a bloc
          const file = new Blob([new Uint8Array(array)], {type: 'image/png'});
          // Lo convertimos a archivo para poder agregarle una imagen
          const file2 = new File([file], this.folio+".png",{ type: "image/png" })

          // Creamos el documento
          let formData = new FormData();
          // agregamos el documento
          formData.append("file", file2);
          formData.append("id_usuario"    , this.getdatosUsuario.iderp  );
          formData.append("idactas_admin" , this.acta.idactas_admin  );
          // Guardamos el documento
          this.$http.post('crear-firma-acta/' + this.getdatosUsuario.iderp, formData).then(response=>{
            this.cargar = false
            this.signaturePad.clear();
            this.dialogFirma = false
            this.validarSuccess('Documento firmado correctamente')
            this.initialize( )
          }).catch(error=>{
            this.validarError(error.body.message)
          })
        }
      },

      limpiar (){
        this.signaturePad.clear();
      },
    },
  }
</script>


